/* eslint-disable prettier/prettier */
<template>
  <div class="app-context mobile-layout">
    <div class="context mobile">
      <div class="feedback" v-if="isView === 0">
        <div class="question">
          <p class="q-title">병원을 내원하셨나요?</p>
          <div class="switch-visit">
            <div class="choice">
              <p class="item">아니오</p>
              <p class="item">네</p>
            </div>
            <div class="switch_wrap">
              <label class="switch">
                <input
                  type="checkbox"
                  id="visit"
                  v-model="feedback.visit"
                  @change="deleteStarCount2()"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="f-divider"></div>
        <div class="evaluate" v-if="feedback.visit">
          <p class="e-title">상세한 평가를 해주세요</p>
          <div class="item-wrap">
            <div
              class="item"
              v-for="(evalItem, index) in feedback.evaluate"
              :key="index + 'A'"
            >
              <p class="i-title">{{ evalItem.title }}</p>
              <div class="star-wrap">
                <star-rating
                  :rating="rating.number"
                  :rounded-corners="true"
                  :activeColor="rating.activeColor"
                  :inactiveColor="rating.inactiveColor"
                  :star-points="rating.starPoint"
                  :starSize="rating.starSize"
                  :increment="1"
                  @rating-selected="setRating(index, $event)"
                ></star-rating>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <p class="c-title">추가 입력 사항</p>
          <div class="textarea-wrap">
            <textarea
              v-model="feedback.content"
              placeholder="상세한 평가를 해주세요."
            />
          </div>
        </div>
        <div class="f-btn-wrap" v-if="feedback.visit">
          <button
            type="button"
            :class="{
              active:
                this.feedback.evaluate[0].flag &&
                this.feedback.evaluate[1].flag &&
                this.feedback.evaluate[2].flag,
            }"
            @click="feedbackSubmit()"
          >
            등록완료
          </button>
        </div>
        <div class="f-btn-wrap" v-else>
          <button
            type="button"
            :class="{
              active: this.feedback.content !== '',
            }"
            @click="feedbackSubmit()"
          >
            등록완료
          </button>
        </div>
        <a class="hidden" @click="winClose()">테스트</a>
      </div>
      <div class="complete" v-if="isView === 1">
        <p>만족도 조사기 <b>완료</b>되었습니다.</p>
        <p>DrRefer 서비스를 이용해 주셔서 감사합니다.</p>
      </div>
      <div class="complete" v-if="isView === 2">
        <p>만족도 조사 페이지 요청이 <b>만료</b>되었습니다.</p>
        <p>만족도 조사 페이지 요청이 만료되어 진행할 수 없습니다.</p>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import StarRating from "vue-star-rating";

export default {
  components: {
    DefaultPopup,
    StarRating,
  },
  data() {
    return {
      isView: 0,
      feedYn: "",
      feedback: {
        visit: true,
        evaluate: [
          {
            title: "시설",
            flag: false,
            rating: 0,
          },
          {
            title: "의료전문성",
            flag: false,
            rating: 0,
          },
          {
            title: "친절도",
            flag: false,
            rating: 0,
          },
        ],
        content: "",
        feedId: {
          flag: false,
          value: "",
        },
        starAverage: null,
      },
      rating: {
        starPoint: [
          23,
          2,
          14,
          17,
          0,
          19,
          10,
          34,
          7,
          50,
          23,
          43,
          38,
          50,
          36,
          34,
          46,
          19,
          31,
          17,
        ],
        activeColor: "#ff7334",
        inactiveColor: "#ffe7dc",
        starSize: 35,
      },
      starOn: require("@/assets/images/star.svg"),
      starOff: require("@/assets/images/star_empty.svg"),
      popupSet: {},
    };
  },
  created() {
    this.getFeedId();
    this.getFeedData();
  },
  mounted() {
    this.deleteStarCount();
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("util", ["GET_FEEDBACK_DATA"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("util", ["FEEDBACK_SUBMIT", "BRING_FEEDBACK_DATA"]),
    getFeedId() {
      this.feedback.feedId.flag = true;
      this.feedback.feedId.value = this.$route.params.code;
    },
    async getFeedData() {
      //console.log("feedId in FBM : " + this.feedback.feedId.value);

      if(this.feedback.feedId.value === null 
            || this.feedback.feedId.value === undefined)
        this.feedback.feedId.value = "";

        var feedIdBoo = /^[0-9]{8,14}$/;

      if(!feedIdBoo.test(this.feedback.feedId.value)){
        this.SET_POPUP(true);
        this.isView = 2;
        this.popupSet.title = "요청실패";
        this.popupSet.content =
          "만료되었거나 동의내역을 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    
      try {
        await this.BRING_FEEDBACK_DATA(this.feedback.feedId.value).then(() => {
          //console.log(this.GET_FEEDBACK_DATA.feedYn);
          if (this.GET_FEEDBACK_DATA.feedYn !== "Y") {
            this.feedYn = this.GET_FEEDBACK_DATA.feedYn;
            this.isView = 0;
          } else {
            this.SET_POPUP(true);
            this.isView = 2;
            this.popupSet.title = "요청실패";
            this.popupSet.content =
              "만료되었거나 동의내역을 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    winClose() {
      window.open("", "_self", "");
      window.close();
    },
    deleteStarCount() {
      if (this.feedback.visit) {
        for (
          let i = 0;
          i < document.querySelector(".item-wrap").childElementCount;
          i++
        ) {
          /*console.log(
            "아이템",
            document.querySelector(".item-wrap").childNodes[i]
          );*/
          document
            .querySelector(".item-wrap")
            .childNodes[i].querySelector(
              ".vue-star-rating-rating-text"
            ).style.display = "none";
        }
      }
    },
    deleteStarCount2() {
      this.$nextTick((_) => {
        this.deleteStarCount();
      });
    },
    setRating(index, rating) {
      this.feedback.evaluate[index].flag = true;
      this.feedback.evaluate[index].rating = rating;
    },
    getStarAverage() {
      let numValue = 0;
      for (let i = 0; i < this.feedback.evaluate.length; i++) {
        numValue += Number(this.feedback.evaluate[i].rating);
      }
      const average = numValue / 3;
      this.feedback.starAverage = average;
    },
    checkFlag() {
      //console.log("체크 내원값", this.feedback.visit);
      if (this.feedback.visit) {
        if (
          !this.feedback.evaluate[0].flag ||
          !this.feedback.evaluate[1].flag ||
          !this.feedback.evaluate[2].flag
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "별점 확인";
          this.popupSet.content =
            "체크하지 않은 항목이 있습니다. 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return false;
        }
        this.getStarAverage();
        return true;
      } else {
        if (this.feedback.content === "") {
          //console.log("진3입");
          this.SET_POPUP(true);
          this.popupSet.title = "입력확인";
          this.popupSet.content = "내원하지 않은 사유를 작성해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return false;
        }
        return true;
      }
    },
    async feedbackSubmit() {
      if (this.checkFlag()) {
        try {
          const feedback = {
            visitYn: this.feedback.visit === true ? "Y" : "N",
            feedId: this.feedback.feedId.value,
          };
          if (this.feedback.visit) {
            feedback.addConts = this.feedback.content;
            (feedback.fsilScore = this.feedback.evaluate[0].rating),
              (feedback.proScore = this.feedback.evaluate[1].rating),
              (feedback.famiScore = this.feedback.evaluate[2].rating),
              (feedback.allScore = this.feedback.starAverage);
          } else {
            feedback.visitSayu = this.feedback.content;
          }
          //console.log("feedback", feedback);

          // 유효성 검사
          // 1. 기타사항
          if(feedback.addConts === null 
            || feedback.addConts === undefined)
            feedback.addConts = "";

          if(feedback.addConts < 0 
            || feedback.addConts > 3000){
            console.log("error :", error);
            this.SET_POPUP(true);
            this.popupSet.title = "기타사항 확인";
            this.popupSet.content = "3000자 내로 기타사항을 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 시설평점
          if(feedback.fsilScore === null 
            || feedback.fsilScore === undefined)
            feedback.fsilScore = "";

          var fsilScoreBoo = /^[0-9]{0,3}$/;

          if(!fsilScoreBoo.test(feedback.fsilScore)){
            console.log("error :", error);
            this.SET_POPUP(true);
            this.popupSet.title = "시설평점 확인";
            this.popupSet.content = "시설평점을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 3. 전문성평점
          if(feedback.proScore === null 
            || feedback.proScore === undefined)
            feedback.proScore = "";

          var proScoreBoo = /^[0-9]{0,3}$/;

          if(!proScoreBoo.test(feedback.proScore)){
            console.log("error :", error);
            this.SET_POPUP(true);
            this.popupSet.title = "전문성평점 확인";
            this.popupSet.content = "전문성평점을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 4. 친절평점
          if(feedback.famiScore === null 
            || feedback.famiScore === undefined)
            feedback.famiScore = "";

          var famiScoreBoo = /^[0-9]{0,3}$/;

          if(!famiScoreBoo.test(feedback.famiScore)){
            console.log("error :", error);
            this.SET_POPUP(true);
            this.popupSet.title = "친절평점 확인";
            this.popupSet.content = "친절평점을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 5. 전체평점
          if(feedback.allScore === null 
            || feedback.allScore === undefined)
            feedback.allScore = "";

          var allScoreBoo = /^[0-9]{0,3}$/;

          if(!allScoreBoo.test(feedback.allScore)){
            console.log("error :", error);
            this.SET_POPUP(true);
            this.popupSet.title = "전체평점 확인";
            this.popupSet.content = "전체평점을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          await this.FEEDBACK_SUBMIT(feedback).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "내원후기 등록성공";
            this.popupSet.content = "내원후기가 성공적으로 등록되었습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.isView = 1;
          });
        } catch (error) {
          console.log("error :", error);
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-context .context.mobile {
  padding: 0 !important;
  .complete {
    position: relative;
    padding: 35px 0 0 0;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #146f83;
      top: 0;
    }
    p {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      b {
        font-weight: bold;
        color: #146f83;
      }
    }
  }
  .feedback {
    width: 100%;
    padding: 40px;
    .question {
      p.q-title {
        margin: 0 0 40px 0;
        text-align: center;
      }
      .switch-visit {
        position: relative;
        width: 190px;
        margin: 0 auto;
        min-height: 34px;
        .choice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: inherit;
          p.item {
            line-height: 34px;
          }
        }
        .switch_wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .switch {
            width: 67px;
            height: 34px;
            margin: 0;
            input {
              &:checked + .slider {
                background: #ffe7dc;
                &:before {
                  transform: translateX(34px);
                }
              }
            }
            .slider {
              background: #ffe7dc;
              &:before {
                position: absolute;
                content: "";
                height: 34px;
                width: 34px;
                left: 0;
                bottom: 0;
                background-color: #ff7334;
                transition: 0.4s;
              }
            }
          }
        }
      }
    }
    .f-divider {
      width: 100%;
      height: 1px;
      background: #707070;
      margin: 30px 0;
    }
    .evaluate {
      p.e-title {
        margin: 0 0 30px 0;
        text-align: center;
      }
      .item-wrap {
        width: 100%;
        margin: 0 0 40px 0;
        .item {
          display: flex;
          align-items: center;
          width: inherit;
          margin: 40px 0 0 0;
          &:first-of-type {
            margin: 0;
          }
          p.i-title {
            width: 105px;
          }
          .star-wrap {
            width: calc(100% - 105px);
            .vue-star-rating {
              span.vue-star-rating-rating-text {
                display: none;
              }
            }
          }
        }
      }
    }
    .content {
      p.c-title {
        margin: 0 0 20px 0;
        text-align: center;
      }
      .textarea-wrap {
        width: 100%;
        border: 1px solid #707070;
        textarea {
          font-family: "AppleSDGothicNeoM00";
          font-size: 14px;
          line-height: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #231815;
          border: 0;
          width: inherit;
          background: transparent;
          padding: 15px;
          overflow: hidden;
          resize: none;
          height: 120px;
          -moz-user-select: auto;

          -webkit-user-select: auto;

          -ms-user-select: auto;

          user-select: auto;
        }
      }
    }
    .f-btn-wrap {
      height: 55px;
      width: 100%;
      margin: 30px auto 0 auto;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      button {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background: #e5e5e5;
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: 0;
        height: inherit;
        width: inherit;
        font-family: "SUIT";
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        &.active {
          background: #146f83;
        }
      }
    }

    p {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-family: "AppleSDGothicNeoM00";
      font-size: 14px;
      line-height: 17px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
}
a.hidden {
  display: none;
}
</style>
